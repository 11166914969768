import React from 'react'
import { graphql, useStaticQuery  } from 'gatsby'

import Layout from '../components/Layout'

export default function Price() {
    const data = useStaticQuery(graphql`
    query getPrices {
        allPriceJson(filter: {applicableYear: {eq: "2022"}}) {
          nodes {
            newPrice {
              Forsyningsledning
              HovedAnlaeg
              Stikledning
              others
            }
            driftPrice {
              Afgift
              BidargRegionV
              FastAfgift
              ForsentIndbetaling
              ManglendeBetaling
              PrisPerM3
            }
            applicableYear
          }
        }
      }
      `)
  
  
  
  
  const prices = data.allPriceJson.nodes[0]


    
      
    return (
        <Layout>
            <div className="card">
                <p className="card-header-title">
                    Priser for {prices.applicableYear}
                </p>
                <div className="card-content">
                    <div className="content">
                    <p className="card-header-title">
                        Ved nyetablering:
                    </p>
                        <table className='table is-narrow price' style={{margin: '0px 50px', width:'90%'}}> 
                     
                                    <tbody>
                                    <tr key="1" >
                                        <th >Forsyningsledning</th>
                                        <th className='has-text-right'>{prices.newPrice.Forsyningsledning +' kr.'}</th>
                                    </tr>
                                    <tr key="2">
                                        <th>Hovedanlæg</th>
                                        <th className='has-text-right'>{prices.newPrice.HovedAnlaeg +' kr.'}</th>
                                    </tr>
                                    <tr key="3">
                                        <th>Stikledning</th>
                                        <th className='has-text-right'>{prices.newPrice.Stikledning +' kr.'}</th>
                                    </tr> 
                                    <tr key="4">
                                        <th>{prices.newPrice.others}</th>
                                        
                                    </tr>   
                                    </tbody>
                                </table>


                    <p className="card-header-title">
                        Driftsbidrag:
                    </p>
                        <table className='table is-narrow price' style={{margin: '0px 50px', width:'90%'}}> 
                     
                                    <tbody>
                                    <tr key="1" >
                                        <th >Fast afgift</th>
                                        <th className='has-text-right'>{prices.driftPrice.FastAfgift +' kr.'}</th>
                                    </tr>
                                    <tr key="2">
                                        <th>Pris per m3</th>
                                        <th className='has-text-right'>{prices.driftPrice.PrisPerM3 +' kr.'}</th>
                                    </tr>
                                    <tr key="3">
                                        <th>Grøn afgift mm.</th>
                                        <th className='has-text-right'>{prices.driftPrice.Afgift +' kr.'}</th>
                                    </tr> 
                                    <tr key="4">
                                        <th>Bidrag fælles forsyningsledning i region vest.</th>
                                        <th className='has-text-right' >{prices.driftPrice.BidargRegionV +' kr.'}</th>
                                        
                                    </tr>  
                                    <tr key="5">
                                        <th>Gebyr for sent indbetaling, per påbegyndt måned.</th>
                                        <th className='has-text-right'>{prices.driftPrice.ForsentIndbetaling +' kr.'}</th>
                                        
                                    </tr>  
                                    <tr key="6">
                                        <th>Gebyr for sent indlevering af aflæsning, per påbegyndt måned.</th>
                                        <th className='has-text-right'>{prices.driftPrice.ForsentIndbetaling +' kr.'}</th>
                                    </tr> 
                                    <tr key="7">
                                        <th>Gebyr for genåbning af vandet, på grund af manglende betaling plus restancen.</th>
                                        <th className='has-text-right'>{prices.driftPrice.ManglendeBetaling +' kr.'}</th>
                                    </tr>
                                </tbody>
                            </table>
                            <p className='has-text-right'>
                                Alle priser er exklusive moms.
                            </p>
                                
                    </div>
                </div>
            </div>
        </Layout>
    )
}
